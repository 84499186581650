<template>
  <div class="timing-detail-wrap row">
    <div class="timing-detail">
      <p class="caption">Outbound</p>

      <p class="detail-info">
        <span class="type"> Departure </span>
        {{ estimatedTimings?.outboundDepartTime || "--:--" }}
      </p>
      <p class="detail-info">
        <span class="type"> Arrive </span>
        {{ estimatedTimings?.outboundArriveTime || "--:--" }}
      </p>
    </div>
    <div class="timing-detail">
      <p class="caption">Return</p>

      <p class="detail-info">
        <span class="type"> Departure </span>
        {{ estimatedTimings?.returnDepartTime || "--:--" }}
      </p>
      <p class="detail-info">
        <span class="type"> Arrive </span>
        {{ estimatedTimings?.returnArriveTime || "--:--" }}
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: "EstTime",
    props: {
      estimatedTimings: {
        type: Object,
      },
    },
  };
</script>

<style lang="scss">
  .timing-detail-wrap {
    color: #585858;
    border: 1px solid #eee;
    padding: 0.25rem !important;
    flex-wrap: wrap;
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    gap: 0.25rem;
    width: 100%;

    .timing-detail {
      width: calc((100% - 0.5rem) / 2);
    }
    .detail-info {
      padding-left: 0.25rem;
      font-size: 0.8rem;
      display: flex;
      flex-direction: row;
      gap: 0.25rem;
      align-items: center;
      .type {
        font-size: 0.7rem;
        width: 60px;
        color: $col_alpha;
      }
    }
    p.caption {
      padding: 0.1rem 0.25rem;
      background: $col_alpha;
      border-radius: 10px;
      color: #fff;
      text-align: center;
      font-size: 0.75rem;
      width: 100%;
    }
  }
</style>
